<template>
    <DialogPortal to="#root">
        <DialogOverlay
            class="fixed inset-0 z-50 bg-gray-500/75 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        <DialogContent v-bind="forwarded" :class="dialogContentClass">
            <slot />
            <DialogClose class="absolute top-0 right-0 p-1 pr-2 pt-2 rounded-tr-md" />
        </DialogContent>
    </DialogPortal>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
    DialogContent,
    type DialogContentEmits,
    type DialogContentProps,
    DialogOverlay,
    DialogPortal,
    useForwardPropsEmits,
} from "reka-ui";

const props = defineProps<DialogContentProps & { id?: HTMLAttributes["id"]; class?: HTMLAttributes["class"] }>();
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;
    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const DIALOG_CONTENT_CLASS =
    "fixed left-1/2 top-1/2 z-50 grid w-full max-w-xl -translate-x-1/2 -translate-y-1/2 gap-4 border border-gray-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg";
const dialogContentClass = computed(() => cn(DIALOG_CONTENT_CLASS, props.class));
</script>
